import Login from '../views/Login';
import Dashboard from '../views/Dashboard';
import Insights from '../views/Insights';
import Flows from '../views/flows/Flows';
import Users from '../views/users/Users';
import Acl from '../views/acl/Acl';
import AclTypes from '../views/acl/Types';
import AclRules from '../views/acl/Rules';
import Services from '../views/service-config/Services';
import AuthConfigContainer from '../views/service-config/ServiceConfigContainer';
import ServiceConfig from '../views/service-config/ServiceConfig';
import QuotasView from '../views/quotas/QuotasView';
import PublicFilesView from '../views/public-files/PublicFilesView';
import SystemConfigView from '../views/system-config/SystemConfigView';
import ModuleDetailsView from '../views/modules/module-details/ModuleDetailsView';
import Modules from '../views/modules/Modules';
import config from '@/utils/config';

function getDocsUrl(path) {
    const url = new URL(path, config.APPMIXER_DOCS_URL);
    return url.href;
}

export default [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        children: [
            {
                path: 'insights',
                name: 'Metrics',
                component: Insights,
                meta: {
                    description: 'See the aggregated metrics for your Appmixer tenant.',
                    auth: true
                }
            },
            {
                path: 'flows',
                name: 'Flows',
                component: Flows,
                meta: {
                    description: 'Find flows, see their metadata, metrics and jump to their logs.',
                    auth: true
                }
            },
            {
                path: 'users',
                name: 'Users',
                component: Users,
                meta: {
                    description: 'Find, edit and delete users or jump their flows.',
                    auth: true
                }
            },
            {
                path: 'acl',
                component: Acl,
                meta: {
                    auth: true
                },
                children: [
                    {
                        path: '',
                        name: 'Access Control List',
                        component: AclTypes,
                        meta: {
                            description: `Limit access to connectors for specific user groups or restrict access to other resources within Appmixer. Visit <a target=_blank href="${getDocsUrl('tutorials/setting-acl')}">Setting ACL</a> or <a target=_blank href="${getDocsUrl('tutorials/appmixer-virtual-users#configure-acl-rules-for-a-scope')}">Configure ACL rules for a scope</a> pages of the online documentation to learn more about ACLs and their common use.`,
                            auth: true
                        }
                    },
                    {
                        path: ':type',
                        name: 'Access Control List Types',
                        component: AclRules,
                        meta: {
                            auth: true
                        }
                    }
                ]
            },
            {
                path: 'service-config',
                component: AuthConfigContainer,
                meta: {
                    auth: true
                },
                children: [
                    {
                        path: '',
                        name: 'Connectors Configuration',
                        component: Services,
                        meta: {
                            // eslint-disable-next-line max-len
                            description: 'Configure your connectors. For example, provide \'clientId\' and \'clientSecret\' properties in your connector configuration for OAuth 2 connectors.',
                            auth: true
                        }
                    },
                    {
                        path: ':serviceId',
                        name: 'Connectors Configuration',
                        component: ServiceConfig,
                        meta: {
                            // eslint-disable-next-line max-len
                            description: 'Configure your connectors. For example, provide \'clientId\' and \'clientSecret\' keys and their values in your connector configuration for all your OAuth 2 connectors.',
                            auth: true
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'modules',
                name: 'Connectors',
                component: Modules,
                meta: {
                    // eslint-disable-next-line max-len
                    description: 'Install new connectors or update connectors that you have installed before. Installed connectors are immediately available to you in the Appmixer Studio.',
                    auth: true
                }
            },
            {
                path: 'modules/:module',
                name: 'Connector Details',
                component: ModuleDetailsView,
                meta: {
                    // eslint-disable-next-line max-len
                    description: 'Install, update, remove or download connector as a zip file to inspect its source code.',
                    auth: true
                },
                props: true

            },
            {
                path: 'public-files',
                name: 'Public Files',
                component: PublicFilesView,
                meta: {
                    description: 'Upload files to the root of your API.',
                    auth: true
                }
            },
            {
                path: 'quotas',
                name: 'Quotas',
                component: QuotasView,
                meta: {
                    description: 'Quota definitions for your connectors. Quota definitions define the throughput ' +
                        'restrictions for connector components. These restrictions are especially useful ' +
                        'for connectors that call API endpoints with strict rate limits.',
                    auth: true
                }
            },
            {
                path: 'system-config',
                name: 'System Configuration',
                component: SystemConfigView,
                meta: {
                    description: `Set global system configurations to control various aspects of Appmixer. See <a target=_blank href="${getDocsUrl('appmixer-backoffice/system-configuration')}">System Configuration</a> documentation for more details.`,
                    auth: true
                }
            }
        ]
    },
    { path: '*', redirect: '/dashboard/insights' }

];
